<script setup lang="ts">
import { PlusIcon, CheckIcon, ArrowLeftIcon } from "@heroicons/vue/20/solid";
import { promiseTimeout } from "@vueuse/core";
import Fuse from "fuse.js";

const dayjs = useDayjs();
const { $client } = useNuxtApp();
const route = useRoute();
const currentBot = ref(route.params.activeBotId as string);

const { data: articles, refresh } = await $client.wiki.list.useQuery({
  botId: currentBot.value,
});
const { data: users } = await $client.user.list.useQuery();

const categories = computed(() => {
  return [...new Set(articles.value.map((article) => article.category))]
    .filter((c) => c !== "")
    .map((c) => ({ name: c, value: c }));
});
const subCategories = computed(() => {
  return [
    ...new Set(
      articles.value
        // .filter((a) => filter.value.category.includes(a.category))
        .map((article) => article.subCategory)
    ),
  ]
    .filter((c) => c !== "")
    .map((c) => ({ name: c, value: c }));
});

const subCategoriesAll = computed(() => {
  return [...new Set(articles.value.map((article) => article.subCategory))]
    .filter((c) => c !== "")
    .map((c) => ({ name: c, value: c }));
});

const filter = ref({
  search: (route.query.search as string) || "",
  status:
    (route.query.status as "DRAFT" | "PUBLISHED" | "ARCHIVED" | undefined) ||
    undefined,
  author: route.query?.author ? (route.query.author as string).split(",") : [],
  category: route.query?.category
    ? (route.query.category as string).split(",")
    : [],
  subCategory: route.query?.subCategory
    ? (route.query.subCategory as string).split(",")
    : [],
});

watch(
  filter,
  () => {
    const query = {} as any;

    if (filter.value.search !== "") query.search = filter.value.search;
    // else query.search = undefined;

    if (filter.value.status !== undefined) query.status = filter.value.status;
    // else query.status = undefined;

    if (filter.value.author.length > 0)
      query.author = filter.value.author.join(",");
    // else query.author = undefined;

    if (filter.value.category.length > 0)
      query.category = filter.value.category.join(",");
    // else query.category = undefined;

    if (filter.value.subCategory.length > 0)
      query.subCategory = filter.value.subCategory.join(",");
    // else query.subCategory = undefined;

    navigateTo({
      query,
      replace: true,
    });
  },
  {
    deep: true,
  }
);

const articlesFiltered = computed(() => {
  let tmpArticles = articles.value;

  if (filter.value.search !== "") {
    const fuse = new Fuse(tmpArticles, {
      keys: [{ name: "title", weight: 2 }, "content"],
      threshold: 0.3,
    }).search(filter.value.search);
    tmpArticles = fuse.map((result) => result.item);
  }

  return tmpArticles.filter((article) => {
    if (filter.value.status) {
      if (article.status !== filter.value.status) {
        return false;
      }
    }

    if (filter.value.author.length > 0) {
      if (article.user?.id === undefined) {
        return false;
      }
      if (!filter.value.author.includes(article.user?.id)) {
        return false;
      }
    }

    if (filter.value.category.length > 0) {
      if (article.category === undefined) {
        return false;
      }
      if (!filter.value.category.includes(article.category)) {
        return false;
      }
    }

    if (filter.value.subCategory.length > 0) {
      if (article.subCategory === undefined) {
        return false;
      }
      if (!filter.value.subCategory.includes(article.subCategory)) {
        return false;
      }
    }

    return true;
  });
});

const selectedArticle = ref("");
watch(
  () => route.params.articleId,
  (articleId) => {
    selectedArticle.value = articleId as string;
  },
  {
    immediate: true,
  }
);

const closeArticle = async () => {
  selectedArticle.value = "";

  await promiseTimeout(200);
  navigateTo({
    path: `/bots/${currentBot.value}/wiki`,
    replace: true,
  });
};
const updateArticle = async (
  id: string,
  status: "DRAFT" | "PUBLISHED" | "ARCHIVED"
) => {
  await $client.wiki.edit.mutate({
    id,
    status,
  });
  refresh();
};

const deleteArticle = async (id: string) => {
  await $client.wiki.delete.mutate({
    id,
  });
  refresh();
};

const selected = ref<string[]>([]);
const selectToggleAll = (add: boolean) => {
  if (add) {
    selected.value = articlesFiltered.value.map((article) => article.id);
  } else {
    selected.value = [];
  }
};

const selectToggle = (id: string) => {
  if (selected.value.includes(id)) {
    selected.value = selected.value.filter((s) => s !== id);
  } else {
    selected.value = [...selected.value, id];
  }
};

const selectedArticlesAllowPublish = computed(() => {
  return selected.value.some(
    (id) =>
      articlesFiltered.value.find((article) => article.id === id)?.status ===
      "DRAFT"
  );
});

const publishSelected = async () => {
  await Promise.all(
    selected.value
      .filter(
        (id) => articles.value.find((a) => a.id === id)?.status === "DRAFT"
      )
      .map((id) => updateArticle(id, "PUBLISHED"))
  );
  selected.value = [];
};

const { data: permission } = await $client.user.getPermissionScope.useQuery({
  scope: {
    articleEdit: "wdb.edit",
    articleAdd: "wdb.add",
    articleDelete: "wdb.delete",
  },
});
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="inline-flex justify-between">
      <div class="mb-4 flex h-[40px] flex-none items-center justify-between">
        <p class="text-xl">Wissensdatenbank<br /></p>
      </div>
      <div>
        <CustomButton
          type="secondary"
          classes="bg-transparent"
          :to="`/bots/${currentBot}/bots/edit`"
        >
          <ArrowLeftIcon class="h-6 w-6" />
          zurück zum Baukasten
        </CustomButton>
      </div>
    </div>
    <div class="h-full">
      <div class="h-fit divide-y rounded-3xl border bg-white">
        <div class="flex h-fit flex-none justify-between">
          <div class="flex h-[81px] items-center gap-x-6 px-9">
            <CustomTabNav :to="`/bots/${currentBot}/wiki`" label="Artikel" />
          </div>
          <div class="flex gap-x-3 px-8 py-6">
            <ModalOuterWrapper v-slot="{ isOpen, open, close, wasOpen }">
              <LazyModalOpenArticle
                v-if="wasOpen"
                :open="isOpen === 'create'"
                :bot-id="currentBot"
                :categories="categories"
                :sub-categories="subCategoriesAll"
                is-new
                @refresh="refresh()"
                @close="close()"
              />
              <CustomButton
                v-show="selectedArticlesAllowPublish"
                type="tertiary"
                :disabled="!permission.articleEdit"
                @click="publishSelected()"
              >
                Alle veröffentlichen
              </CustomButton>
              <CustomButton
                type="primary"
                :disabled="!permission.articleAdd"
                @click="open('create')"
              >
                <PlusIcon class="h-4 w-4" />
                Artikel erstellen
              </CustomButton>
            </ModalOuterWrapper>
          </div>
        </div>
        <div class="flex min-h-full flex-grow flex-col pt-8">
          <div
            class="mx-8 flex flex-none flex-wrap items-center justify-between gap-y-1"
          >
            <div class="flex gap-x-3">
              <CustomPill
                :value="filter.status === undefined"
                @click="filter.status = undefined"
              >
                Alle Artikel
                <template #counter>{{ articles.length }}</template>
              </CustomPill>
              <CustomPill
                :value="filter.status === 'DRAFT'"
                @click="filter.status = 'DRAFT'"
              >
                Entwurf
                <template #counter>
                  {{ articles.filter((a) => a.status === "DRAFT").length }}
                </template>
              </CustomPill>
              <CustomPill
                :value="filter.status === 'PUBLISHED'"
                @click="filter.status = 'PUBLISHED'"
              >
                Veröffentlicht
                <template #counter>
                  {{ articles.filter((a) => a.status === "PUBLISHED").length }}
                </template>
              </CustomPill>
              <CustomPill
                :value="filter.status === 'ARCHIVED'"
                @click="filter.status = 'ARCHIVED'"
              >
                Archiviert
                <template #counter>
                  {{ articles.filter((a) => a.status === "ARCHIVED").length }}
                </template>
              </CustomPill>
            </div>
            <div class="ml-auto flex gap-x-3 self-end">
              <CustomFilterSearch
                v-model:value="filter.search"
                class="max-w-[250px]"
                placeholder="Artikel suchen"
              />
              <CustomFilterPopup
                v-model:values="filter.author"
                class="max-w-[250px]"
                label="Authoren"
                multiple
                :options="
                  users.map((u) => ({
                    name: u.firstname + ' ' + u.lastname,
                    value: u.id,
                  }))
                "
              />
              <CustomFilterPopup
                v-model:values="filter.category"
                :disabled="categories.length === 0"
                class="max-w-[250px]"
                label="Kategorien"
                multiple
                :options="categories"
              />
              <CustomFilterPopup
                v-model:values="filter.subCategory"
                :disabled="subCategories.length === 0"
                class="max-w-[250px]"
                label="Unter Kategorien"
                multiple
                :options="subCategories"
              />
            </div>
          </div>
          <div class="h-full flex-grow">
            <div class="mx-8 flex h-[64px] flex-row">
              <div
                class="flex h-full flex-none basis-9 items-center justify-start"
              >
                <button
                  class="flex h-5 w-5 items-center justify-center rounded-md border"
                  :class="[
                    selected.length === articlesFiltered.length
                      ? 'border-primary hover:border-primary-hover'
                      : 'border-tertiary hover:border-tertiary-hover',
                  ]"
                  @click="
                    selectToggleAll(selected.length !== articlesFiltered.length)
                  "
                >
                  <CheckIcon
                    v-show="selected.length === articlesFiltered.length"
                    class="h-5 w-5 fill-primary"
                  />
                </button>
              </div>
              <div
                class="flex h-full basis-2/5 items-center justify-start truncate"
              >
                <p class="text-table-header opacity-60">Name</p>
              </div>
              <div
                class="flex h-full basis-1/4 items-center justify-start truncate"
              >
                <p class="text-table-header opacity-60">Status</p>
              </div>
              <div
                class="flex h-full basis-1/4 items-center justify-start truncate"
              >
                <p class="text-table-header opacity-60">Kategorie</p>
              </div>
              <div
                class="flex h-full basis-1/4 items-center justify-start truncate"
              >
                <p class="text-table-header opacity-60">Unter Kategorie</p>
              </div>
              <div
                class="flex h-full basis-1/4 items-center justify-start truncate"
              >
                <p class="text-table-header opacity-60">
                  Letzte Aktualisierung
                </p>
              </div>
              <div
                class="flex h-full flex-none basis-14 items-center justify-end"
              />
            </div>
            <template v-for="article in articlesFiltered" :key="article.id">
              <hr class="mx-8" />
              <ModalOuterWrapper v-slot="{ isOpen, open, close, wasOpen }">
                <LazyModalDelete
                  v-if="wasOpen"
                  :title="`Sind Sie sicher das Sie den Artikel '${article.title}' löschen wollen?`"
                  text="Ja, Artikel löschen"
                  :open="isOpen === 'delete'"
                  @close="close"
                  @confirm="deleteArticle(article.id)"
                />
                <div
                  class="flex h-16 w-full flex-row px-8 last:rounded-b-3xl hover:bg-gray-50"
                >
                  <div
                    class="flex h-full flex-none basis-9 items-center justify-start"
                  >
                    <button
                      class="flex h-5 w-5 items-center justify-center rounded-md border"
                      :class="[
                        selected.includes(article.id)
                          ? 'border-primary hover:border-primary-hover'
                          : 'border-tertiary hover:border-tertiary-hover',
                      ]"
                      @click="selectToggle(article.id)"
                    >
                      <CheckIcon
                        v-show="selected.includes(article.id)"
                        class="h-5 w-5 fill-primary"
                      />
                    </button>
                  </div>
                  <NuxtLink
                    class="flex h-full basis-2/5 items-center justify-start truncate"
                    :to="{
                      path: `/bots/${currentBot}/wiki/${article.id}`,
                    }"
                  >
                    {{ article.title }}
                  </NuxtLink>
                  <NuxtLink
                    class="flex h-full basis-1/4 items-center justify-start truncate"
                    :to="{
                      path: `/bots/${currentBot}/wiki/${article.id}`,
                    }"
                  >
                    <CustomBadge
                      v-if="article.status === 'DRAFT'"
                      text="Entwurf"
                      type="gray"
                    />
                    <CustomBadge
                      v-if="article.status === 'PUBLISHED'"
                      text="Veröffentlicht"
                      type="green"
                    />
                    <CustomBadge
                      v-if="article.status === 'ARCHIVED'"
                      text="Archiviert"
                      type="yellow"
                    />
                  </NuxtLink>
                  <NuxtLink
                    class="flex h-full basis-1/4 items-center justify-start truncate"
                    :to="{
                      path: `/bots/${currentBot}/wiki/${article.id}`,
                    }"
                  >
                    {{ article.category }}
                  </NuxtLink>
                  <NuxtLink
                    class="flex h-full basis-1/4 items-center justify-start truncate"
                    :to="{
                      path: `/bots/${currentBot}/wiki/${article.id}`,
                    }"
                  >
                    {{ article.subCategory }}
                  </NuxtLink>
                  <NuxtLink
                    class="flex h-full basis-1/4 items-center justify-start truncate"
                    :to="{
                      path: `/bots/${currentBot}/wiki/${article.id}`,
                    }"
                  >
                    <p class="">
                      {{ dayjs(article.updatedAt).fromNow() }}
                    </p>
                  </NuxtLink>
                  <div
                    class="flex h-full flex-none basis-14 items-center justify-end"
                  >
                    <CustomMenuButton
                      :buttons="[
                        {
                          label: 'Deaktivieren',
                          emit: 'deactivate',
                          type:
                            article.status !== 'DRAFT' && permission.articleEdit
                              ? 'base'
                              : 'disabled',
                        },
                        {
                          label: 'Archivieren',
                          emit: 'archive',
                          type:
                            article.status !== 'ARCHIVED' &&
                            permission.articleEdit
                              ? 'base'
                              : 'disabled',
                        },
                        {
                          label: 'Löschen',
                          emit: 'delete',
                          type: permission.articleDelete
                            ? 'delete'
                            : 'disabled',
                        },
                      ]"
                      @click="
                        [
                          $event === 'deactivate'
                            ? updateArticle(article.id, 'DRAFT')
                            : null,
                          $event === 'archive'
                            ? updateArticle(article.id, 'ARCHIVED')
                            : null,
                          $event === 'delete' ? open('delete') : null,
                        ]
                      "
                    />
                  </div>
                </div>
              </ModalOuterWrapper>
            </template>
          </div>
        </div>
      </div>
    </div>
    <ModalOpenArticle
      :open="selectedArticle !== ''"
      :article-id="selectedArticle"
      :bot-id="currentBot"
      :categories="categories"
      :sub-categories="subCategoriesAll"
      @close="closeArticle()"
      @refresh="refresh()"
    />
  </div>
</template>
