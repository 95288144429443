<script setup lang="ts">
const props = defineProps({
  to: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
});

const route = useRoute();
const value = computed(() => route.path === props.to);
</script>

<template>
  <div class="">
    <NuxtLink
      :to="props.to"
      class="border-y-2 py-2 text-lg font-medium transition-all duration-150"
      :class="[
        value
          ? 'border-b-primary border-t-transparent'
          : 'border-transparent text-misc-text-inactive-base text-opacity-50 hover:border-b-tertiary-hover hover:text-opacity-70',
      ]"
    >
      {{ props.label }}
    </NuxtLink>
  </div>
</template>
