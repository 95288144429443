<script setup lang="ts">
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  error: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  options: {
    type: Array as PropType<
      { name: string; value: string; [key: string]: string }[]
    >,
    default: () => [],
  },
  label: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: () => Math.random().toString(36).substring(2, 15),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "blur"]);

const value = computed({
  get: () => props.options.find((option) => option.value === props.modelValue),
  set: (val) => emit("update:modelValue", val?.value),
});
// watch(
//   () => props.modelValue,
//   () => {
//     value.value = props.options.find(
//       (option) => option.value === props.modelValue
//     ) || { name: "", value: "" };
//   }
// );

const query = ref("");

const filteredOptions = computed(() => {
  if (query.value === "") return props.options;

  const potential = props.options.filter((option) => {
    return option.name.toLowerCase().includes(query.value.toLowerCase());
  });

  if (
    !props.options.find(
      (option) =>
        option.name.toLocaleLowerCase() === query.value.toLocaleLowerCase()
    )
  ) {
    potential.unshift({
      value: query.value.trim(),
      name: query.value.trim(),
    });
    // return potential;
  }

  if (!potential.length) {
    return [{ value: query.value.trim(), name: query.value.trim() }];
  } else {
    return potential;
  }
  // if (
  //   props.options.filter((option) => option.name === query.value).length === 0
  // ) {
  //   return [{ value: query.value.trim(), name: query.value.trim() }];
  // } else {
  //   return props.options.filter((option) => {
  //     return option.name.toLowerCase().includes(query.value.toLowerCase());
  //   });
  // }
});
</script>

<template>
  <HeadlessCombobox
    v-model="value"
    as="div"
    class="input-grid group h-fit w-full"
  >
    <HeadlessComboboxLabel
      class="grid-label block text-xs leading-6 text-input-label text-opacity-80"
      :for="props.label"
    >
      {{ props.label }}
    </HeadlessComboboxLabel>
    <div class="grid-left mr-3" :class="{ hidden: !$slots.left }">
      <slot name="left" />
    </div>
    <div
      class="grid-center inline-flex w-full items-center gap-x-3 rounded-xl ring-1 ring-inset"
      :class="[
        props.disabled
          ? 'ring-input-disabled'
          : error.length > 0
          ? 'ring-red-500'
          : 'ring-input focus-within:ring-2 focus-within:ring-input-focus group-hover:ring-input-hover',
      ]"
    >
      <div class="relative grow">
        <HeadlessComboboxInput
          class="z-10 w-full truncate rounded-xl bg-transparent px-5 py-3 pr-10 text-left text-sm font-normal outline-none disabled:cursor-not-allowed disabled:text-default-text disabled:text-opacity-30"
          :disabled="props.disabled"
          :display-value="(option: any) => option?.name"
          @change="query = $event.target.value"
        />
        <!-- @blur="emit('blur')" -->
        <HeadlessComboboxButton
          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          <ChevronUpDownIcon
            :class="[
              'h-6 w-6',
              props.disabled
                ? 'fill-input-disabled'
                : 'fill-input-misc group-hover:fill-input-misc-hover',
            ]"
            aria-hidden="true"
          />
        </HeadlessComboboxButton>
        <!-- <HeadlessTransitionChild
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        > -->
        <HeadlessComboboxOptions
          v-if="filteredOptions.length > 0"
          class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <HeadlessComboboxOption
            v-for="option in filteredOptions"
            :key="option.value"
            v-slot="{ active }"
            :value="option"
            as="template"
          >
            <div
              :class="[
                'cursor-pointer select-none rounded-md py-2 pl-3 pr-9',
                active ? 'bg-alternative' : '',
              ]"
            >
              <span
                :class="['inline-flex items-center truncate']"
                :value="option"
              >
                <slot :option="option" name="option">
                  {{ option.name }}
                </slot>
              </span>
            </div>
          </HeadlessComboboxOption>
        </HeadlessComboboxOptions>
        <!-- </HeadlessTransitionChild> -->
      </div>
    </div>
    <div class="grid-right ml-3" :class="{ hidden: !$slots.right }">
      <slot name="right" />
    </div>
    <div class="grid-bottom">
      <p v-if="error.length" class="text-sm text-red-500">
        {{ error[0] }}
      </p>
      <slot v-else name="bottom" />
    </div>
  </HeadlessCombobox>
</template>

<style scoped>
.input-grid {
  display: grid;

  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 44px auto;
  grid-template-areas: ". label ." "left center right" ". bottom .";
  @apply gap-y-1;
}

.grid-label {
  grid-area: label;
}

.grid-left {
  grid-area: left;
}

.grid-center {
  grid-area: center;
}

.grid-right {
  grid-area: right;
}

.grid-bottom {
  grid-area: bottom;
}
</style>
