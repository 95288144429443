<script setup lang="ts">
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";

const emit = defineEmits(["update:value", "input"]);
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const value = computed({
  get: () => props.value,
  set: (newValue) => {
    emit("update:value", newValue);
    emit("input", newValue);
  },
});
</script>

<template>
  <div class="group">
    <div class="relative">
      <input
        v-model="value"
        type="text"
        class="block w-full rounded-full border border-secondary bg-white py-3 pl-10 pr-4 text-sm leading-5 outline-none outline-offset-0 placeholder:text-input-hover hover:border-secondary-hover focus:border-primary"
        :placeholder="props.placeholder"
      />
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <MagnifyingGlassIcon
          class="h-4 w-4 fill-misc-text-inactive"
          aria-hidden="true"
        />
      </div>
    </div>
  </div>
</template>
