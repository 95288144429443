<script setup lang="ts">
import { z } from "zod";
import { toTypedSchema } from "@vee-validate/zod";

const emit = defineEmits(["close", "refresh"]);
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  articleId: {
    type: String,
    default: "",
  },
  botId: {
    type: String,
    default: "",
  },
  categories: {
    type: Array as PropType<{ name: string; value: string }[]>,
    default: () => [],
  },
  subCategories: {
    type: Array as PropType<{ name: string; value: string }[]>,
    default: () => [],
  },
  disableSync: {
    type: Boolean,
    default: false,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
});

const { $client } = useNuxtApp();

const user = ref(await $client.user.detail.query());
// const localModal = ref(props.modelValue);

const localModal = ref({
  id: "",
  title: "",
  content: "",
  category: "",
  subCategory: "",
  user: "" as "" | { firstname: string; lastname: string },
  status: "CREATE",
});

if (props.articleId !== "") {
  const data = await $client.wiki.detail.query({
    id: props.articleId,
  });

  if (data)
    localModal.value = {
      id: data.id,
      title: data.title,
      content: data.content,
      category: data.category,
      subCategory: data.subCategory,
      user: data.user,
      status: data.status,
    };
}

const { values, defineComponentBinds, validate, meta, resetForm } = useForm({
  initialValues: {
    title: localModal.value.title,
    category: localModal.value.category,
    subCategory: localModal.value.subCategory,
    content: localModal.value.content,
  },
  validationSchema: toTypedSchema(
    z.object({
      title: z.string().nonempty("Bitte geben Sie einen Titel ein"),
      category: z.string().nonempty("Bitte wählen Sie eine Kategorie aus"),
      subCategory: z.string(),
      content: z.string().nonempty("Bitte geben Sie einen Artikeltext ein"),
    })
  ),
});

const title = defineComponentBinds("title", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});
const category = defineComponentBinds("category", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});
const subCategory = defineComponentBinds("subCategory", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});
const content = defineComponentBinds("content", {
  mapProps: (state) => ({
    error: state.errors,
  }),
});

watch(
  () => props.open,
  () => {
    if (props.open) {
      localModal.value = {
        id: "",
        title: "",
        content: "",
        category: "",
        subCategory: "",
        user: "",
        status: "CREATE",
      };
      resetForm();
    }
  }
);

const localCategories = computed(() => {
  if (props.categories.find((c) => c.value === values.category))
    return props.categories;
  else
    return [
      ...props.categories,
      { name: values.category || "", value: values.category || "" },
    ];
});

const localSubCategories = computed(() => {
  if (props.subCategories.find((c) => c.value === values.subCategory))
    return props.subCategories;
  else
    return [
      ...props.subCategories,
      {
        name: values.subCategory || "",
        value: values.subCategory || "",
      },
    ];
});

const create = async (publish: boolean) => {
  const { valid } = await validate();
  if (!valid) return;

  await $client.wiki.add.mutate({
    botId: props.botId,
    title: values.title?.trim() || "",
    content: values.content?.trim() || "",
    category: values.category?.trim() || "",
    subCategory: values.subCategory?.trim() || "",
    status: publish ? "PUBLISHED" : "DRAFT",
  });

  emit("refresh");
  emit("close");
};

const save = async (publish: boolean) => {
  const { valid } = await validate();
  if (!valid) return;

  await $client.wiki.edit.mutate({
    id: localModal.value.id,
    title: values.title?.trim() || "",
    content: values.content?.trim() || "",
    category: values.category?.trim() || "",
    subCategory: values.subCategory?.trim() || "",
    status: publish ? "PUBLISHED" : "DRAFT",
  });

  emit("refresh");
  emit("close");
};

const { data: permission } = await $client.user.getPermissionScope.useQuery({
  scope: {
    articleEdit: "wdb.edit",
  },
});
</script>

<template>
  <ModalInnerWrapper
    :open="props.open"
    class="flex h-[90vh] w-[60vw] max-w-[1000px] flex-col"
    @close="emit('close')"
  >
    <div class="flex flex-row items-center justify-between p-5">
      <p class="h-[37px] text-xl">
        <template v-if="localModal.status === 'CREATE'">
          Artikel erstellen
        </template>
        <template v-if="localModal.status === 'DRAFT'">
          Artikel anlegen
        </template>
        <template v-if="localModal.status === 'PUBLISHED'">
          Artikel bearbeiten
        </template>
        <template v-if="localModal.status === 'ARCHIVED'">
          Artikel bearbeiten
        </template>
        <!-- {{ localModal.status ? "Artikel erstellen" : "Artikel bearbeiten" }} -->
      </p>
      <div class="flex gap-x-3">
        <template v-if="localModal.status === 'CREATE'">
          <CustomButton
            type="tertiary"
            :disabled="!permission.articleEdit"
            @click="create(false)"
          >
            Entwurf speichern
          </CustomButton>
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission.articleEdit"
            @click="create(true)"
          >
            Veröffentlichen
          </CustomButton>
        </template>
        <template v-if="localModal.status === 'DRAFT'">
          <CustomButton
            type="tertiary"
            :disabled="!permission.articleEdit"
            @click="save(false)"
          >
            Entwurf aktualisieren
          </CustomButton>
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission.articleEdit"
            @click="save(true)"
          >
            Veröffentlichen
          </CustomButton>
        </template>
        <template v-if="localModal.status === 'PUBLISHED'">
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission.articleEdit"
            @click="save(true)"
          >
            Aktualisieren
          </CustomButton>
        </template>
        <template v-if="localModal.status === 'ARCHIVED'">
          <CustomButton
            :type="meta.valid ? 'primary' : 'tertiary'"
            :disabled="!permission.articleEdit"
            @click="save(true)"
          >
            Veröffentlichen
          </CustomButton>
        </template>
      </div>
    </div>
    <div class="flex flex-grow flex-col gap-y-4 p-5">
      <InputTextField
        v-bind="title"
        label="Titel"
        autofocus
        :disabled="localModal.status === 'ARCHIVED' || !permission.articleEdit"
      />
      <div class="inline-flex gap-x-3">
        <InputComboBoxField
          v-bind="category"
          label="Kategorie"
          :options="localCategories"
          :disabled="
            localModal.status === 'ARCHIVED' || !permission.articleEdit
          "
        />
        <InputComboBoxField
          v-bind="subCategory"
          label="Unter Kategorie"
          :options="localSubCategories"
          :disabled="
            localModal.status === 'ARCHIVED' || !permission.articleEdit
          "
        />
      </div>
      <InputFakeField
        :model-value="user.firstname + ' ' + user.lastname"
        label="Autor"
        :disabled="localModal.status === 'ARCHIVED' || !permission.articleEdit"
      />
      <InputTextareaField
        v-bind="content"
        label="Text"
        :disabled="localModal.status === 'ARCHIVED' || !permission.articleEdit"
      />
    </div>
  </ModalInnerWrapper>
</template>
